import React from 'react'
import cx from 'classnames'
import { useDevice } from 'device'

import { constants } from 'helpers'
import { usePrices, useResubscribeOffer } from 'modules/subscription'
import { Text } from 'components/dataDisplay'
import { buttonMessages } from 'components/inputs'
import { WidthContainer } from 'components/layout'
import HeaderBannerContainer from 'compositions/banners/HeaderBannerContainer/HeaderBannerContainer'
import ResubscribeButton from 'compositions/buttons/ResubscribeButton/ResubscribeButton'

import messages from './messages'


const ResubscribeBanner: React.FC = () => {
  const { isMobile } = useDevice()
  const { offer, couponCode, isFetching: isCouponDataFetching, eventOffer } = useResubscribeOffer()

  const {
    formattedPriceWithDiscount: priceWithDiscount,
    isFetching: isPricesFetching,
  } = usePrices({ couponCode, skip: isCouponDataFetching })

  const isFetching = isCouponDataFetching || isPricesFetching

  let text: Intl.Message = offer.startsWith('off') ? messages.percentOff : messages[offer]

  return (
    <HeaderBannerContainer
      className="bg-red"
      height={64}
      loading={isFetching}
    >
      <WidthContainer className={cx('flex  items-center', isMobile ? 'justify-between' : 'justify-center')}>
        <Text
          style={isMobile ? 'sh6' : 'sh5'}
          message={{
            ...text,
            values: {
              priceWithDiscount,
              eventOffer,
              fragrancesAmount: constants.businessLogic.fragrancesAmount,
            },
          }}
          html
        />
        <ResubscribeButton
          className={cx('max-w-[128rem] flex-none', isMobile ? 'ml-12' : 'ml-24')}
          title={buttonMessages.resubscribe}
          placement="Header"
          size={38}
        />
      </WidthContainer>
    </HeaderBannerContainer>
  )
}


export default ResubscribeBanner
