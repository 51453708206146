export default {
  title: {
    en: 'Get your Mother’s Day gifts, order by April 29 at 11:59 PM EST!',
  },
  postShipping: {
    en: 'Gift Subscriptions are perfect last-minute Mother’s Day gifts.',
  },
  button: {
    en: 'Shop now',
  },
}
