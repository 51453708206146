import React from 'react'
import { useIntl } from 'intl'
import links from 'links'
import { track } from 'analytics'
import { useUser } from 'modules/user'

import { Icon } from 'components/dataDisplay'
import { Href } from 'components/navigation'

import messages from './messages'


const QueueCounter: React.FunctionComponent = () => {
  const { user, isFetching } = useUser()

  const productsCount = user?.analyticsMetadata?.productsInQueueQty || 0

  const intl = useIntl()
  const label = intl.formatMessage(messages.label)

  if (isFetching) {
    return (
      <Icon name="32/loader" color="white" />
    )
  }

  return (
    <Href
      className="relative cursor-pointer text-white"
      to={links.queue}
      aria-label={label}
      onClick={() => {
        track('Queue icon click', {
          productsInQueueQty: productsCount,
        })
      }}
      data-testid="queue"
    >
      <Icon name="32/queue" />
      {
        productsCount > 0 ? (
          <span
            className="size-s16 text-eye3 absolute right-0 top-0 flex items-center justify-center rounded-full border-2 border-black bg-white text-black"
            data-testid="cartCount"
          >
            {productsCount}
          </span>
        ) : (
          <Icon className="absolute right-0 top-0 rounded-full border border-black" name="16/info" color="red" />
        )
      }
    </Href>
  )
}


export default QueueCounter
