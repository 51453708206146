export default {
  title: {
    en: 'Order your Father`s Day gifts by June 3 at 11:59 PM EST!',
  },
  postShipping: {
    en: 'Gift Subscriptions are perfect last-minute Father’s Day gifts.',
  },
  button: {
    en: 'Shop now',
  },
}
