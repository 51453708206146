export default {
  discount: {
    en: 'Get {discount} off your first month',
  },
  freeTrial: {
    en: 'Get your free perfume now',
  },
  freeProduct: {
    en: 'Get a free product in your first order',
  },
  freeMonth: {
    en: 'Get your second month for free',
  },
  noDiscountText: {
    en: 'Get your first month of Scentbird for {price}',
  },
}
