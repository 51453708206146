import React from 'react'

import DefaultLimitedDropBanner from './components/DefaultLimitedDropBanner/DefaultLimitedDropBanner'
import VaultLimitedDropBanner from './components/VaultLimitedDropBanner/VaultLimitedDropBanner'

import useLimitedDropBanner from './utils/useLimitedDropBanner'


const COMPONENTS_MAP = {
  ComponentLimitedDropDefaultTopBarBanner: DefaultLimitedDropBanner,
  ComponentLimitedDropVaultTopBarBanner: VaultLimitedDropBanner,
}

type LimitedDropBannerProps = {
  position?: number
}

const LimitedDropBanner: React.FunctionComponent<LimitedDropBannerProps> = (props) => {
  const { position } = props

  const { data, isFetching } = useLimitedDropBanner()

  if (!data || isFetching) {
    return null
  }

  const { endDate, settings, startDate } = data
  const { __typename } = settings

  const Component = COMPONENTS_MAP[__typename]

  if (!Component) {
    return null
  }

  return <Component endDate={endDate} position={position} settings={settings} startDate={startDate} />
}

export default LimitedDropBanner
