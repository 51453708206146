import React from 'react'
import cx from 'classnames'
import links from 'links'
import { useDevice } from 'device'

import { Text } from 'components/dataDisplay'
import { Button, buttonMessages } from 'components/inputs'

import HeaderBannerContainer from 'compositions/banners/HeaderBannerContainer/HeaderBannerContainer'

import messages from './messages'


type TheSummerEditBannerProps = {
  position?: number
}

const TheSummerEditBanner: React.FC<TheSummerEditBannerProps> = ({ position }) => {
  const { isMobile } = useDevice()

  return (
    <HeaderBannerContainer
      className="bg-gold-50"
      to={links.shop.theEdit}
      height={(isMobile ? 64 : 56)}
      trackParams={{
        placement: 'Header',
        content: 'The Summer Edit',
        action: 'link',
        link: links.shop.theEdit,
        position,
      }}
      data-testid="theSummerEditBanner"
    >
      <div className={cx('flex items-center justify-between gap-16 pl-12 pr-16', isMobile && 'w-full')}>
        <Text align="left" style={isMobile ? 'h9' : 'h8'} message={messages.title} html />
        <Button className="px-16" size={38} noPadding title={buttonMessages.shopNow} />
      </div>
    </HeaderBannerContainer>
  )
}

export default TheSummerEditBanner
