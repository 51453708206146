export default {
  defaultTitle: {
    en: 'It’s the holiday season!',
  },
  valentinesDayGiftHubSaleNotification: {
    en: 'Get up to 60% OFF select Valentine’s Day gifts!',
  },
  valentinesDaySaleNotification: {
    en: 'The best last-minute Valentine’s gift? A Scentbird subscription, of course.',
  },
  title: {
    en: '{discount}% off select products',
  },
  button: {
    en: 'Shop now',
  },
}
