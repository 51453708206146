import React from 'react'
import cx from 'classnames'

import { useDevice } from 'device'
import { WidthContainer, type WidthContainerProps } from 'components/layout'

import s from './MainHeader.module.css'


type MainHeaderProps = {
  className?: string
  size?: WidthContainerProps['size']
}

const MainHeader: React.CFC<MainHeaderProps> = (props) => {
  const { children, className, size } = props

  const { isMobile } = useDevice()

  return (
    <div className="flex items-stretch bg-black">
      {/* this div and main-header required for mobile navigation */}
      <div className={s.placeholder} />
      {/* header should have relative class (not WidthContainer) for proper work of flyout menu on desktop */}
      <header id="main-header" className={cx(s.header, className, 'relative flex flex-1 items-stretch bg-black text-white', isMobile && 'z-overlay')}>
        <WidthContainer className="flex w-full items-center" size={size}>
          {children}
        </WidthContainer>
      </header>
    </div>
  )
}


export default MainHeader
