import React, { useEffect } from 'react'
import { useIntl } from 'intl'
import { openModal, preloadModal } from 'modals'

import { track } from 'analytics'
import { Icon } from 'components/dataDisplay'

import messages from './messages'


const Search: React.FunctionComponent = () => {
  const intl = useIntl()

  const label = intl.formatMessage(messages.label)

  useEffect(() => {
    void preloadModal('searchModal')
  }, [])

  const handleClick = () => {
    track('Search input click')
    openModal('searchModal')
  }

  return (
    <button
      className="cursor-pointer text-white"
      type="button"
      aria-label={label}
      data-testid="search"
      onClick={handleClick}
    >
      <Icon name="32/search" />
    </button>
  )
}


export default Search
