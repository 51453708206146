export default {
  noDiscount: {
    en: 'Resubscribe today to access our catalog of over {fragrancesAmount} scents.',
  },
  discount: {
    en: 'Resubscribe and get your next month for {priceWithDiscount}.',
  },
  percentOff: {
    en: 'Take {eventOffer} your first month back when you resubscribe.',
  },
  freeProduct: {
    en: 'Resubscribe and get a FREE fragrance in your next order.',
  },
  freeCase: {
    en: 'Resubscribe and get a FREE case in your next order.',
  },
  freeMonth: {
    en: 'Resubscribe today, and your next month is FREE.',
  },
}
