import React from 'react'
import { useDevice } from 'device'
import { track } from 'analytics'
import { useSubscribeOffer } from 'modules/subscription'
import { BackgroundImage, Text } from 'components/dataDisplay'
import HeaderBannerContainer from 'compositions/banners/HeaderBannerContainer/HeaderBannerContainer'

import bgMobile from './images/bg-mobile.jpg'
import bgDesktop from './images/bg-desktop.jpg'

import messages from './messages'


type TextByOffer = Record<SubscriptionModule.OfferType, Intl.Message>

const textByOffer: TextByOffer = {
  freeTrial: messages.freeTrial,
  free2ndMonth: messages.freeMonth,
  freeProduct: messages.freeProduct,
  discount: messages.discount,
}

type BannerWithImageBaseProps = {
  position: number
  to?: string
  subtitle?: Intl.Message | string
  children?: React.ReactElement
  withBgImage?: boolean
  'data-testid'?: string
}

const HolidayBaseBanner: React.FC<BannerWithImageBaseProps> = (props) => {
  const { position, children, subtitle, to, withBgImage, 'data-testid': dataTestId } = props
  const { isMobile } = useDevice()

  const {
    discountText,
    formattedPriceWithDiscount,
    formattedPrice,
    formattedExtraShippingPrice,
    offerType,
  } = useSubscribeOffer()


  const price = formattedPriceWithDiscount || formattedPrice

  const title = {
    ...textByOffer[offerType] || messages.noDiscountText,
    values: {
      discount: discountText,
      price,
      extraShippingPrice: formattedExtraShippingPrice,
    },
  }

  const content = (
    <div className="flex h-full flex-col items-center justify-center text-center">
      {
        subtitle ? (
          <>
            <Text className="text-cream-light" message={subtitle} style="h9" />
            <Text className="mt-4" message={title} color="white" style="h6" />
          </>
        ) : (
          children
        )
      }
    </div>
  )

  const wrapperClassName = 'h-full w-full bg-dark-red'

  return (
    <HeaderBannerContainer
      to={to}
      height={isMobile ? 64 : 56}
      onClick={() => {
        if (to) {
          track('Banner click', {
            placement: 'Header',
            content: 'Holiday season',
            action: 'link',
            link: to,
            position,
          })
        }
      }}
      data-testid={dataTestId}
    >
      {
        withBgImage ? (
          <BackgroundImage className={wrapperClassName} src={isMobile ? bgMobile : bgDesktop} sizes="100vw">
            {content}
          </BackgroundImage>
        ) : (
          <div className={wrapperClassName}>
            {content}
          </div>
        )
      }
    </HeaderBannerContainer>
  )
}

export default HolidayBaseBanner
