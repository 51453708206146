import React from 'react'
import cx from 'classnames'
import links from 'links'
import { useDevice } from 'device'

import { Text } from 'components/dataDisplay'
import { Button, buttonMessages } from 'components/inputs'
import HeaderBannerContainer from 'compositions/banners/HeaderBannerContainer/HeaderBannerContainer'

import messages from './messages'


const CardUpdateBanner: React.FC = () => {
  const { isMobile } = useDevice()

  return (
    <HeaderBannerContainer
      className="z-floating-header bg-red sticky top-0"
      height={64}
      to={links.cardUpdate}
      trackParams={{
        placement: 'Header',
        content: 'Payment method update',
        action: 'link',
        link: links.cardUpdate,
      }}
      data-testid="headerCardUpdateBanner"
    >
      <div className={cx('flex  items-center', isMobile ? 'justify-between' : 'justify-center')}>
        <Text
          style={isMobile ? 'sh6' : 'sh5'}
          message={messages.text}
          html
        />
        <Button
          className="ml-24 flex-none"
          title={buttonMessages.update}
          size={38}
          tabIndex={-1}
        />
      </div>
    </HeaderBannerContainer>
  )
}


export default CardUpdateBanner
