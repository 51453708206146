import { useMemo } from 'react'
import { usePathname } from 'router'
import links from 'links'
import { constants } from 'helpers'
import { useFeatureIsOn } from '@growthbook/growthbook-react'

import messages from './messages'


type UseHolidaySeasonBannerProps = {
  discount?: number
}

const useHolidaySeasonBanner = ( { discount }: UseHolidaySeasonBannerProps) => {
  const pathname = usePathname()

  const isValentinesHeaderBannerEnabled = useFeatureIsOn(constants.features.valentinesHeaderBanner)

  const message = useMemo(() => {
    if (discount) {
      return {
        ...messages.title,
        values: { discount },
      }
    }

    if (isValentinesHeaderBannerEnabled) {
      return pathname === links.gifts ? messages.valentinesDayGiftHubSaleNotification : messages.valentinesDaySaleNotification
    }

    return messages.defaultTitle
  }, [ discount, isValentinesHeaderBannerEnabled, pathname ])

  return {
    message,
    link: discount ? links.shop.holiday : links.gifts,
    withBgImage: !isValentinesHeaderBannerEnabled,
  }
}

export default useHolidaySeasonBanner
