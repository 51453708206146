import React from 'react'
import links from 'links'
import { useDevice } from 'device'

import BannerBase from '../BannerBase/BannerBase'
import messages from './messages'


type PrivateSaleBanner = {
  className?: string
  position: number
}

const PrivateSaleBanner: React.FunctionComponent<PrivateSaleBanner> = ({ className, position }) => {
  const { isMobile } = useDevice()

  return (
    <BannerBase
      className={className}
      title={messages.title}
      titleStyle={isMobile ? 'eye2' : undefined}
      to={links.privateSale}
      height={(isMobile ? 64 : 56)}
      trackParams={{
        placement: 'Header',
        content: 'Private sale',
        action: 'link',
        link: links.privateSale,
        position,
      }}
      data-testid="privateSaleBanner"
    />
  )
}


export default PrivateSaleBanner
