import React from 'react'
import cx from 'classnames'
import { useDevice } from 'device'
import links from 'links'
import { constants } from 'helpers'
import { useFeatureIsOn } from '@growthbook/growthbook-react'

import { Text } from 'components/dataDisplay'
import { Button } from 'components/inputs'
import HeaderBannerContainer from 'compositions/banners/HeaderBannerContainer/HeaderBannerContainer'

import messages from './messages'


const HEIGHT_DESKTOP = 56
const HEIGHT_MOBILE = 64

type MothersDayBannerProps = {
  position?: number
}

const MothersDayBanner: React.FunctionComponent<MothersDayBannerProps> = (props) => {
  const { position } = props

  const { isMobile } = useDevice()
  const isMothersDayPostShippingCutOffEnabled = useFeatureIsOn(constants.features.mothersDayPostShippingCutOff)

  const title = isMothersDayPostShippingCutOffEnabled ? messages.postShipping : messages.title

  return (
    <HeaderBannerContainer
      className="bg-dark-pink"
      to={links.gifts}
      height={isMobile ? HEIGHT_MOBILE : HEIGHT_DESKTOP}
      trackParams={{
        action: 'link',
        content: 'Marketing promo',
        contentDetails: 'Mother’s day',
        link: links.gifts,
        placement: 'Header',
        position,
      }}
      data-testid="holidayMothersDayBanner"
    >
      <div className={cx('flex h-full items-center justify-center gap-16', isMobile && 'px-16')}>
        <Text message={title} color="white" style={isMobile ? 'h9' : 'h8'} />
        <Button className="shrink-0 px-8" size={38} noPadding title={messages.button} />
      </div>
    </HeaderBannerContainer>
  )
}

export default MothersDayBanner
