import React from 'react'
import cx from 'classnames'
import links from 'links'
import { useDevice } from 'device'

import BannerBase from '../BannerBase/BannerBase'
import messages from './messages'


type DriftSubscriptionBannerProps = {
  className?: string
  position?: number
}

const DriftSubscriptionTextBanner: React.FunctionComponent<DriftSubscriptionBannerProps> = ({ className, position = 1 }) => {
  const { isMobile } = useDevice()

  return (
    <BannerBase
      className={cx(className, 'px-8 text-center')}
      title={messages.title}
      to={links.driftSubscription}
      height={(isMobile ? 64 : 56)}
      trackParams={{
        placement: 'Header',
        content: 'Drift subscription',
        action: 'link',
        link: links.driftSubscription,
        position,
      }}
      html
      data-testid="driftSubscriptionTextBanner"
    />
  )
}


export default DriftSubscriptionTextBanner
