import React from 'react'
import cx from 'classnames'
import links from 'links'
import { useDevice } from 'device'

import { useUser } from 'modules/user'

import { Text } from 'components/dataDisplay'
import { Button, buttonMessages } from 'components/inputs'

import HeaderBannerContainer from 'compositions/banners/HeaderBannerContainer/HeaderBannerContainer'

import messages from './messages'


type VipSubscriberSaleBannerProps = {
  position?: number
}

const VipSubscriberSaleBanner: React.FC<VipSubscriberSaleBannerProps> = ({ position }) => {
  const { isMobile } = useDevice()

  const { gender } = useUser()

  const link = gender === 'FEMALE' ? links.shop.travelSize.perfumes : links.shop.travelSize.colognes

  return (
    <HeaderBannerContainer
      className="bg-gold-50"
      to={link}
      height={(isMobile ? 64 : 56)}
      trackParams={{
        placement: 'Header',
        content: 'VIP Subscriber sale',
        action: 'link',
        link,
        position,
      }}
      data-testid="vipSubscriberSaleBanner"
    >
      <div className={cx('flex items-center justify-between gap-16 pl-12 pr-16', isMobile && 'w-full')}>
        <Text align="left" style={isMobile ? 'h9' : 'h8'} message={messages.title} html />
        <Button className="px-16" size={38} noPadding title={buttonMessages.shopNow} />
      </div>
    </HeaderBannerContainer>
  )
}

export default VipSubscriberSaleBanner
