import React from 'react'
import links from 'links'
import { useDevice } from 'device'

import BannerBase from '../BannerBase/BannerBase'
import messages from './messages'


type TossInBannerProps = {
  className?: string
  position: number
}

const TossInBanner: React.FunctionComponent<TossInBannerProps> = ({ className, position }) => {
  const { isMobile } = useDevice()

  return (
    <BannerBase
      className={className}
      title={messages.title}
      to={links.tossIn}
      height={(isMobile ? 64 : 56)}
      trackParams={{
        placement: 'Header',
        content: 'Toss-in',
        action: 'link',
        link: links.tossIn,
        position,
      }}
      data-testid="tossInBanner"
    />
  )
}


export default TossInBanner
