import React from 'react'
import cx from 'classnames'
import links from 'links'
import { useDevice } from 'device'

import { Text } from 'components/dataDisplay'
import { Button, buttonMessages } from 'components/inputs'

import HeaderBannerContainer from 'compositions/banners/HeaderBannerContainer/HeaderBannerContainer'

import messages from './messages'


type VaultLimitedDropBannerProps = {
  endDate: string;
  position?: number
  startDate: string;
}

const VaultLimitedDropBanner: React.FC<VaultLimitedDropBannerProps> = (props) => {
  const { endDate, position, startDate } = props

  const { isMobile } = useDevice()

  return (
    <HeaderBannerContainer
      className="bg-gold-50"
      to={links.currentLimitedDrop}
      height={(isMobile ? 64 : 56)}
      trackParams={{
        placement: 'Header',
        content: 'Limited Drop The Vault',
        action: 'link',
        link: links.currentLimitedDrop,
        position,
      }}
      data-testid="vaultLimitedDropBanner"
    >
      <div className={cx('flex items-center justify-between gap-16 pl-12 pr-16', isMobile && 'w-full')}>
        <Text align="left" style={isMobile ? 't5' : 'h8'} message={{ ...messages.text, values: { endDate, startDate } }} html />
        <Button className="px-16" size={38} noPadding title={buttonMessages.shopNow} />
      </div>
    </HeaderBannerContainer>
  )
}

export default VaultLimitedDropBanner
