import React from 'react'
import cx from 'classnames'
import { useDevice } from 'device'

import { constants } from 'helpers'
import { Icon, Text } from 'components/dataDisplay'
import { useUnskipSubscription } from 'compositions/buttons/UnskipButton/util'
import HeaderBannerContainer from 'compositions/banners/HeaderBannerContainer/HeaderBannerContainer'

import messages from './messages'


const UnskipBanner: React.FC = () => {
  const { isMobile } = useDevice()
  const { handleUnskipSubscription, isSubmitting, isFetching } = useUnskipSubscription({ placement: 'banner' })

  return (
    <HeaderBannerContainer
      height={64}
      className="bg-gold-50"
      loading={isFetching || isSubmitting}
      trackParams={{
        placement: 'Header',
        content: 'Unskip',
      }}
      onClick={handleUnskipSubscription}
    >
      <div className={cx('flex items-center justify-center', isMobile ? 'flex-col' : '')}>
        <Text
          style={isMobile ? 'sh6' : 'sh5'}
          message={{
            ...messages.subTitle,
            values: {
              fragrancesAmount: constants.businessLogic.fragrancesAmount,
            },
          }}
        />
        <div className={cx('flex flex-none items-center', isMobile ? 'mt-4' : 'ml-24')}>
          <Text style="h7" message={messages.title} />
          <Icon className="ml-12 flex-none" name="other/long-arrow-right" />
        </div>
      </div>
    </HeaderBannerContainer>
  )
}


export default UnskipBanner
