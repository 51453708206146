import React from 'react'
import cx from 'classnames'
import links from 'links'
import { useDevice } from 'device'

import { Icon, Text } from 'components/dataDisplay'
import HeaderBannerContainer from 'compositions/banners/HeaderBannerContainer/HeaderBannerContainer'

import messages from './messages'


const UndeliverableBanner: React.FunctionComponent = () => {
  const { isMobile } = useDevice()

  return (
    <HeaderBannerContainer
      className="bg-red"
      height={64}
      to={links.profile.shipping}
      trackParams={{
        placement: 'Header',
        content: 'Undeliverable address',
        action: 'link',
        link: links.profile.shipping,
      }}
      data-testid="undeliverableBanner"
    >
      <div className={cx('flex items-center justify-center', isMobile ? 'flex-col' : '')}>
        <Text
          style={isMobile ? 'sh6' : 'sh5'}
          message={messages.message}
        />
        <div className={cx('flex flex-none items-center', isMobile ? 'mt-4' : 'ml-24')}>
          <Text style="h7" message={messages.button} />
          <Icon className="ml-12 flex-none" name="other/long-arrow-right" />
        </div>
      </div>
    </HeaderBannerContainer>
  )
}


export default UndeliverableBanner
