import React from 'react'
import links from 'links'
import { useDevice } from 'device'

import BannerBase from '../BannerBase/BannerBase'

import messages from './messages'


type GwpTextBannerProps = {
  position?: number
}

const GwpTextBanner: React.FC<GwpTextBannerProps> = ({ position }) => {
  const { isMobile } = useDevice()

  return (
    <BannerBase
      className="px-8 text-center"
      title={messages.title}
      to={links.shop.theEdit}
      titleStyle={isMobile ? 'h9' : 'h8'}
      height={(isMobile ? 64 : 56)}
      trackParams={{
        placement: 'Header',
        content: 'Gift With Purchase',
        action: 'link',
        link: links.shop.theEdit,
        position,
      }}
      html
      data-testid="gwpTextBanner"
    />
  )
}

export default GwpTextBanner
