import React from 'react'
import links from 'links'
import { track } from 'analytics'
import { useLocationState } from 'router'
import { useUser } from 'modules/user'

import { Text } from 'components/dataDisplay'
import { Href } from 'components/navigation'
import { buttonMessages } from 'components/inputs'


const UserNavWrapper: React.FunctionComponent<{ children: React.ReactElement, withRedirect: boolean }> = ({ children, withRedirect }) => {
  const { isLoggedIn, isFetching } = useUser()
  const { currentUrl } = useLocationState()

  if (isFetching) {
    return null
  }

  if (!isLoggedIn) {
    // ATTN it shouldn't cause any issues with landings, but we can exclude landings if it will
    return (
      <Href
        to={`${links.login}${withRedirect ? `?redirect=${encodeURIComponent(currentUrl)}` : ''}`}
        onClick={() => {
          track('Login button click')
        }}
        data-testid="loginButton"
      >
        <Text message={buttonMessages.login} style="t2" />
      </Href>
    )
  }

  return children
}


export default UserNavWrapper
