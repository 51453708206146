import { useMemo } from 'react'
import dayjs from 'date'

import { useLimitedDropSale } from 'modules/limitedDrops'


const useLimitedDropBanner = () => {
  const { data: limitedDropData, isFetching } = useLimitedDropSale()

  const data = useMemo(() => {
    if (!limitedDropData) {
      return
    }

    const { entryPointsMap, entryPointsUsageMap, saleData } = limitedDropData
    const { endDate, startDate } = saleData

    const bannerId = entryPointsUsageMap?.topBar
    const bannerSettings = entryPointsMap[bannerId]

    return {
      endDate: dayjs(endDate).tz('America/New_York').format('MMM Do'),
      settings: bannerSettings,
      startDate: dayjs(startDate).tz('America/New_York').format('MMM Do'),
    }
  }, [ limitedDropData ])

  return {
    data: data,
    isFetching,
  }
}

export default useLimitedDropBanner
