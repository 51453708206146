import React from 'react'
import { useFt } from 'hooks'
import { constants } from 'helpers'
import loadable from '@loadable/component'
import { useDevice } from 'device'
import { Bone } from 'components/layout'


const Desktop = loadable(
  () => import('compositions/Header/view/Desktop/Desktop'),
  { fallback: <Bone className="bg-black" pw={100} h={64} /> }
)
const Mobile = loadable(
  () => import('compositions/Header/view/Mobile/Mobile'),
  { fallback: <Bone className="bg-black" pw={100} h={48} /> }
)

import type { MobileProps } from './view/Mobile/Mobile'
import type { DesktopProps } from './view/Desktop/Desktop'


type HeaderProps = MobileProps & DesktopProps

const Header: React.FunctionComponent<HeaderProps> = (props) => {
  const { withBanner, withSubHeader, withSupNav, withQueueCounter, loginWithBackRedirect, logoLink } = props
  const { isMobile } = useDevice()

  const isChatAssistanceEnabled = useFt(constants.features.budgieChatAssistance)

  if (isMobile) {
    return (
      <Mobile
        withBanner={withBanner}
        withSubHeader={withSubHeader}
        logoLink={logoLink}
        withQueueCounter={withQueueCounter}
        loginWithBackRedirect={loginWithBackRedirect}
      />
    )
  }

  return (
    <Desktop
      withBanner={withBanner}
      withSupNav={withSupNav}
      logoLink={logoLink}
      withQueueCounter={withQueueCounter}
      loginWithBackRedirect={loginWithBackRedirect}
      withChatAssistance={isChatAssistanceEnabled}
    />
  )
}


export default Header
