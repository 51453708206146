import React from 'react'

import links from 'links'

import HolidayBaseBanner from '../HolidayBaseBanner/HolidayBaseBanner'

import messages from './messages'


type HolidaySeasonBannerProps = {
  position?: number
}

const BlackFridayBanner: React.FC<HolidaySeasonBannerProps> = ({ position }) => {

  return (
    <HolidayBaseBanner
      subtitle={messages.subtitle}
      to={links.subscribe}
      position={position}
      data-testid="blackFridayBanner"
    />
  )
}

export default BlackFridayBanner
