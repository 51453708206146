import React from 'react'
import { Text } from 'components/dataDisplay'

import HolidayBaseBanner from '../HolidayBaseBanner/HolidayBaseBanner'

import messages from './messages'


type GiftSubscriptionBannerProps = {
  position?: number
}

const GiftSubscriptionBanner: React.FC<GiftSubscriptionBannerProps> = ({ position }) => {
  return (
    <HolidayBaseBanner position={position}>
      <div
        className="flex h-full items-center"
        data-testid="giftSubscriptionBanner"
      >
        <Text
          className="flex-1 text-white"
          message={messages.title}
          style="h8"
        />
      </div>
    </HolidayBaseBanner>
  )
}

export default GiftSubscriptionBanner
