import React from 'react'
import cx from 'classnames'
import links from 'links'
import { useDevice } from 'device'

import BannerBase from '../BannerBase/BannerBase'
import messages from './messages'


type CandleSubscriptionBannerProps = {
  className?: string
  position: number
}

const CandleSubscriptionBanner: React.FunctionComponent<CandleSubscriptionBannerProps> = ({ className, position }) => {
  const { isMobile } = useDevice()

  return (
    <BannerBase
      className={cx(className, 'px-8')}
      title={messages.title}
      titleStyle={isMobile ? 'eye3' : undefined}
      to={links.candleSubscription}
      height={(isMobile ? 64 : 56)}
      trackParams={{
        placement: 'Header',
        content: 'Candle subscription',
        action: 'link',
        link: links.candleSubscription,
        position,
      }}
      data-testid="candleSubscriptionBanner"
    />
  )
}


export default CandleSubscriptionBanner
