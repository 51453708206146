import React from 'react'
import cx from 'classnames'
import { useReferralInvites } from 'modules/user'
import { Text, type TextStyle } from 'components/dataDisplay'
import { navMessages as messages } from 'compositions/navigation'


type ReferralNavItemProps = {
  textClassName?: string
  spanClassName?: string
  freeFragranceClassName?: string
  style?: TextStyle
}

const ReferralNavItem: React.FC<ReferralNavItemProps> = ({ textClassName, spanClassName = '', freeFragranceClassName = '', style = null }) => {
  const { stats: { available } } = useReferralInvites()

  return (
    <Text
      className={cx(textClassName)}
      tag="span"
      message={{
        ...messages.newReferral,
        values: {
          available,
          spanClassName,
          freeFragranceClassName,
        },
      }}
      style={style}
      html
    />
  )
}

export default ReferralNavItem
