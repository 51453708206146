import React from 'react'
import { useDevice } from 'device'
import cx from 'classnames'
import { Icon, Text, type TextStyle } from 'components/dataDisplay'

import HeaderBannerContainer, { type HeaderBannerContainerProps } from 'compositions/banners/HeaderBannerContainer/HeaderBannerContainer'


type BannerBaseProps = Omit<HeaderBannerContainerProps, 'height'> & {
  title: Intl.Message | string
  titleStyle?: TextStyle
  height?: HeaderBannerContainerProps['height']
  html?: boolean
}

const BannerBase: React.FunctionComponent<BannerBaseProps> = (props) => {
  const {
    className,
    title,
    titleStyle = 'h8',
    height: customHeight,
    to,
    loading,
    html,
    trackParams,
    onClick,
    'data-testid': dataTestId,
  } = props

  const { isMobile } = useDevice()
  const height = customHeight || (isMobile ? 48 : 56)

  return (
    <HeaderBannerContainer
      className={cx(className, 'bg-gold-50')}
      to={to}
      height={height}
      loading={loading}
      trackParams={trackParams}
      onClick={onClick}
      data-testid={dataTestId}
    >
      <Text style={titleStyle} message={title} html={html} />
      {
        Boolean(to) && (
          <Icon className="ml-12 flex-none" name="other/long-arrow-right" />
        )
      }
    </HeaderBannerContainer>
  )
}


export default BannerBase
